import React from 'react'
import foto from '../Foto.jpg'

export const About = () => {
    return (
<section id="Acerca" class="about section container full-lg-screen">
<article>
      <img class="imagen-redondeada" src={foto} alt="" />
    </article>
    <article class="text-lg-right">
      <aside class="text-center ">
        <h1>Juan Olivas</h1>
        <h5>Desarrollador</h5>
      </aside>
      <p ></p>
      <p class="text-center">Ingeniero en Sistemas Computacionales con experiencia en el desarrollo de aplicaciones de escritorio, móviles y web. Conocimientos en bases de datos y programación orientada a objetos con la metodología ágil Scrum. Apasionado por la programación y la tecnología, siempre en busca de aprender y aplicar nuevas tecnologías para la resolución de problemas. Excelentes habilidades de comunicación y trabajo.</p>
    </article>
 
  </section>
    );
}