import React from "react";


export const Welcome = () => {
    return ( 
<section id="Inicio">
    <article class="hero-image" style = {{backgroundImage: 'var(--hero-image)', backgroundAttachment: 'var(--hero-attachment)'}} >
      <aside class="hero-image-opacity" style = {{backgroundColor: 'var(--black-alpha-color)'}} >
        <div class="hero-image-content">
          <h2 class="hero-image-title" style={{color: 'var(--white-color)'}} >
            Bienvenidos
          </h2>
          <a href="#Contacto" class="btn">CONTÁCTAME</a>
        </div>
      </aside>
    </article>
</section>
    )
    }