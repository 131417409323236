import React from 'react'
import foto from '../Foto.jpg'

export const Skills = () => {
    return ( 
<div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={foto} class="d-block w-30" alt="..." width="100px" height="100"/>
      <div class="carousel-caption d-none d-md-block">
        <h5>Etiqueta de la primera diapositiva</h5>
        <p>Algún contenido placeholder representativo para la primera diapositiva.</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src={foto} class="d-block w-30" alt="..."width="100px" />
      <div class="carousel-caption d-none d-md-block">
        <h5>Etiqueta de la segunda diapositiva</h5>
        <p>Algún contenido placeholder representativo para la segunda diapositiva.</p>
      </div>
    </div>
    <div class="carousel-item">
      <img src={foto} class="d-block w-30" alt="..." width="100px" />
      <div class="carousel-caption d-none d-md-block">
        <h5>Etiqueta de la tercera diapositiva</h5>
        <p>Algún contenido placeholder representativo para la tercera diapositiva.</p>
      </div>
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Anterior</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Siguiente</span>
  </button>
</div>
);
}