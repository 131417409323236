import React from 'react'
import { Footer } from './Footer';
import { Header } from './Header';
import { About } from './About';
import { Skills } from './Skills';
import { Welcome } from './Welcome';
import { Contact } from './Contact';

export const Home = () => {
  return (
    <>
        <Header />
        <Welcome />
        <About />
        <Skills />
        <Contact />
        <Footer/>
    </>
  )
}